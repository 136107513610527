<template>
  <div v-show="recommendedProducts?.length" :class="brandClasses.container">
    <h2 v-if="content?.title" class="mb-4 lg:mx-9 title-2 lg:mb-6">
      {{ content.title }}
    </h2>
    <client-only>
      <vf-carousel
        v-if="recommendedProducts?.length"
        class="-mx-2"
        :class="brandClasses.carouselContainer"
        :class-controls="['-mt-9 mx-2', brandClasses.carouselControls]"
        loop
      >
        <div
          v-for="({ recToken, ...card }, i) in recommendedProducts"
          :key="i"
          :class="[
            content.variant === '3-cards' ? 'w-full md:w-1/2 lg:w-1/3' : 'w-1/2 md:w-1/4 lg:w-1/6',
            brandClasses.productContainer,
          ]"
          @click="sendProductClickImpression(recToken)"
        >
          <product-card-recommended :currency="currency[locale]" v-bind="card" />
        </div>
      </vf-carousel>
      <template #fallback>
        <div
          class="grid children:skeleton gap-4 lg:px-9"
          :class="content.variant === '3-cards' ? 'md:cols-2 lg:cols-3' : 'cols-2 md:cols-4 lg:cols-6'"
        >
          <template v-for="i in content.variant === '3-cards' ? 1 : 2" :key="i">
            <div class="aspect-square" />
            <div class="aspect-square <md:hidden" />
            <div class="aspect-square <lg:hidden" />
          </template>
        </div>
      </template>
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import type { ProductRecommendations } from '#types/components/cms/product-recommendations'
import type { MonetateExtractedProduct } from '#types/monetate'

const props = defineProps<{
  content: ProductRecommendations
}>()

const { brandClasses } = useAppConfig().components.cms.productRecommendations
const { currency } = useRuntimeConfig().public
const { sendMonetateEvents, sendRecImpressionsEvent, extractProduct } = useMonetate()
const { $gtm } = useNuxtApp()
const locale = useLocale()

const recommendedProducts = computed<MonetateExtractedProduct[]>(() => props.content.recommendedProducts?.map(
  (product) => (extractProduct(product, props.content.showRatings))
))

const sendProductClickImpression = (recToken: string) => {
  const clickedRecommendation = props.content.recommendedProducts.find(
    (product) => product.recToken === recToken
  )
  sendMonetateEvents([
    {
      eventType: 'monetate:record:RecClicks',
      recClicks: [recToken]
    }
  ])
  if (clickedRecommendation) {
    $gtm.push('product.onProductRecClick', clickedRecommendation, {
      action: 'Click Recommendation',
      currency: currency[locale],
      experience: props.content.monetateDecision,
      list: 'Product Recommendation Carousel',
      title: props.content.title
    })
  }
}

onMounted(() => {
  if (props.content.monetateDecision?.name !== 'Control' && props.content.recommendedProducts?.length) {
    sendRecImpressionsEvent(props.content.recommendedProducts, 24)
    $gtm.push('product.onProductRecImpressions', props.content.recommendedProducts, {
      currency: currency[locale],
      experience: props.content.monetateDecision,
      list: 'Product Recommendation Carousel',
      title: props.content.title
    })
  }
})
</script>
