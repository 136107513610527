<template>
  <base-link
    :to="content.target?.url"
    :target="content.target?.targetAttribute"
    class="block duration hover:bg-grey-90 "
    :class="brandClasses.title"
    @click.stop="$emit('click', `${l1} - ${l2} - ${content.title}`)"
  >
    {{ content.title }}
  </base-link>
</template>

<script lang="ts" setup>
import { MegaMenuL1ContextKey, MegaMenuL2ContextKey } from './context'
import type { MenuItem } from '#types/components/cms/mega-menu'

defineProps<{
  content: MenuItem
}>()

defineEmits<{
  click: [label: string]
}>()

const { brandClasses } = useAppConfig().components.cms.megaMenuL3Mobile

const l1 = inject(MegaMenuL1ContextKey)
const l2 = inject(MegaMenuL2ContextKey)
</script>
